import React from 'react';
import get from 'lodash/get';
import backend from '@gepardi/gdpr-backend';
const { User } = backend.api.models;

export default class Base extends React.Component {
  componentWillReceiveProps(nextProps) {
    const isTestUser = get(this, 'props.user.featureAccessLevel', []).includes(
      User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA
    );
    if (isTestUser && nextProps.location.pathname.indexOf('/company/targetgroups') < 0) {
      this.props.navigate('/company/targetgroups');
    }

    // Check nextProps to see if location changed, if so check for login.
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.isLoggedIn();
    }
  }

  render() {
    return <div className="container-fluid">{this.props.children}</div>;
  }
}
