import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { navigate } from '../../actions/navigate';
import * as authActions from '../../actions/auth-actions';
import * as registerActions from '../../actions/register-actions';
import CompanyBase from '../../components/CompanyBase';

export const mapStateToProps = (state, ownProps) => ({
  user: state.userState.userForNavi || {},
  isLoggedIn: authActions.isLoggedIn
});

export const mapDispatchToProps = dispatch => ({
  registerActions: bindActionCreators(registerActions, dispatch),
  navigate: bindActionCreators(navigate, dispatch)
});

const CompanyBaseContainer = connect(mapStateToProps, mapDispatchToProps)(CompanyBase);

// NOTE Use of withRouter due to https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md#quick-solution
export default withRouter(CompanyBaseContainer);
