import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import * as langActions from '../../actions/language-actions';
import * as authActions from '../../actions/auth-actions';

import ChangeRequestNotification from '../ChangeRequestNotification';

import logoFinland from '../../styles/images/ASIAKASTIETO_black.svg';
import logoSweden from '../../styles/images/UC_black.svg';
import logoProff from '../../styles/images/Proff_720x270.png';

import backend from '@gepardi/gdpr-backend';
const { RecordChangeRequest, User } = backend.api.models;

const testUrl = 'https://test.asiakastieto.fi';
const prodUrl = 'https://www.asiakastieto.fi';
const envUrl = window.location.href.includes('test') >= 0 ? testUrl : prodUrl;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: '',
      user: {},
      accessLevel: User.accessLevel.NONE,
      userActions: false
    };
  }

  componentDidMount() {
    this.setState({
      user: this.props.user
    });
    document.getElementById('navbar-toggle').addEventListener('click', e => {
      const { target } = e;
      let attr = target.attributes;
      if (target.nodeName === 'SPAN') attr = target.offsetParent.attributes;
      const isClosed = attr['aria-expanded'].value === 'false';
      if (isClosed) {
        document.getElementById('navbar-collapse-1').classList.remove('collapse');
        attr['aria-expanded'].value = 'true';
      } else {
        document.getElementById('navbar-collapse-1').classList.add('collapse');
        attr['aria-expanded'].value = 'false';
      }
    });
    this.props.history.listen(this.closeNav);
  }

  closeNav = () => {
    document.getElementById('navbar-collapse-1').classList.add('collapse');
    const toggle = document.getElementById('navbar-toggle');
    toggle.attributes['aria-expanded'].value = false;
  };

  changeLanguage = lang => this.props.actions.changeLanguage(lang);

  languagePicker = (text, lang) =>
    this.props.currentLanguage === lang ? (
      <strong className="u-cursor-pointer active">{text}</strong>
    ) : (
      <strong className="u-cursor-pointer">{text}</strong>
    );

  isActive = link => {
    const pathname = this.props.history.location.pathname;
    let classes = pathname.indexOf(link) >= 0 ? 'active' : '';
    if (link === '/company/registers' && pathname === '/company/registers/trash') {
      classes = 'active';
    }
    return classes;
  };

  renderLink = (to, translation) => {
    const { dt, t, user } = this.props;
    let accessLevel = User.accessLevel.NONE;
    if (!isEmpty(user)) accessLevel = user.accessLevel;
    if (accessLevel === User.accessLevel.NONE || !accessLevel) return null;
    const featureAccessLevel = user.featureAccessLevel || [];
    let link;
    if (to === '/company/registers/changerequests') {
      const registers = this.props.registers || [];
      let newRequestsAmount = 0;
      if (registers) {
        try {
          newRequestsAmount = registers
            .map(register =>
              register.recordChangeRequests.filter(
                changeRequest =>
                  changeRequest.status === RecordChangeRequest.STATUS_NEW ||
                  changeRequest.status === RecordChangeRequest.STATUS_INPROGRESS
              )
            )
            .reduce((accumulator, newRequests) => accumulator + newRequests.length, 0);
        } catch (error) {
          newRequestsAmount = 0;
        }
      }
      link = (
        <Link to={to}>
          {newRequestsAmount ? (
            <div className="changerequestsLinkContainer">
              <ChangeRequestNotification
                t={t}
                elementId="changerequestsLink"
                newRequestsAmount={newRequestsAmount}
              />
            </div>
          ) : null}
          <div
            className={this.isActive(to)}
            style={newRequestsAmount ? { position: 'relative', marginRight: '20px' } : {}}
          >
            {dt(translation, translation)}
          </div>
        </Link>
      );
    } else {
      link = (
        <Link to={to}>
          <div className={this.isActive(to)}>{dt(translation, translation)}</div>
        </Link>
      );
    }

    switch (to) {
      case '/company/registers':
        if (
          accessLevel === User.accessLevel.ADMIN ||
          featureAccessLevel.includes(User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA)
        ) {
          link = null;
        }
        break;
      case '/company/phonebook':
        if (featureAccessLevel.includes(User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA)) {
          link = null;
        }
        break;
      case '/company/services':
        if (
          accessLevel === User.accessLevel.ADMIN ||
          featureAccessLevel.includes(User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA)
        ) {
          link = null;
        }
        break;
      case '/company/targetgroups':
        if (
          accessLevel === User.accessLevel.ADMIN ||
          (!featureAccessLevel.includes(User.featureAccessRoles.GEPARDI_MARKKINOINTI) &&
            !featureAccessLevel.includes(User.featureAccessRoles.GDPR_MARKKINOINTI_ILMAN_TULOIK) &&
            !featureAccessLevel.includes(User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA))
        ) {
          link = null;
        }
        break;
      case '/administration':
        if (accessLevel !== User.accessLevel.ADMIN) {
          link = null;
        }
        break;
      case '/administration/scythe':
        if (
          accessLevel !== User.accessLevel.ADMIN &&
          !featureAccessLevel.includes(User.featureAccessRoles.GDPR_SCYTHE_ADMIN)
        ) {
          link = null;
        }
        break;
      case '/faq':
        if (!this.props.faq.length) {
          link = null;
        }
        break;
      case '/settings':
        if (featureAccessLevel.includes(User.featureAccessRoles.ROLE_GDPR_TESTIKAYTTAJA)) {
          link = null;
        }
        break;

      default: {
        break;
      }
    }
    return link;
  };

  logout = () => {
    this.props.actions.logout();
  };

  render() {
    const { t, user } = this.props;
    let accessLevel = User.accessLevel.NONE;
    if (!isEmpty(user)) accessLevel = user.accessLevel;
    let logoutUrl = `${envUrl}/sopimusasiakas/j_spring_security_logout?returnUrl=${envUrl}`;
    const COUNTRY =
      window.location.href.indexOf('proff') >= 0
        ? 'NO'
        : window.location.href.indexOf('uc.se') >= 0
        ? 'SV'
        : 'FI';
    const isFinland = COUNTRY === 'FI';
    return (
      <nav className="navbar">
        <div className="container-fluid">
          <div className="row main-nav">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle visible-xs"
                id="navbar-toggle"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar bar1"></span>
                <span className="icon-bar bar2"></span>
                <span className="icon-bar bar3"></span>
              </button>
              <div className="logo-container visible-xs">
                {COUNTRY === 'FI' && <img src={logoFinland} style={{ width: '220px' }} />}
                {COUNTRY === 'SV' && <img src={logoSweden} style={{ height: '38.5px' }} />}
                {COUNTRY === 'NO' && <img src={logoProff} style={{ width: '150px' }} />}
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbar-collapse-1">
              <div className="desk-top-nav hidden-xs">
                <div className="lang-picker-container">
                  <span>
                    <i className="fa fa-globe"></i>
                  </span>
                  <span className="e-lang-picker" onClick={() => this.changeLanguage('fi')}>
                    {this.languagePicker('FI', 'fi')}
                  </span>
                  <span className="e-lang-picker" onClick={() => this.changeLanguage('en')}>
                    {this.languagePicker('EN', 'en')}
                  </span>
                  <span className="e-lang-picker" onClick={() => this.changeLanguage('sv')}>
                    {this.languagePicker('SV', 'sv')}
                  </span>
                  <span className="e-lang-picker" onClick={() => this.changeLanguage('de')}>
                    {this.languagePicker('DE', 'de')}
                  </span>
                </div>
                <div className="logo-container">
                  {COUNTRY === 'FI' && <img src={logoFinland} style={{ width: '220px' }} />}
                  {COUNTRY === 'SV' && <img src={logoSweden} style={{ height: '38.5px' }} />}
                  {COUNTRY === 'NO' && <img src={logoProff} style={{ width: '150px' }} />}
                </div>
                <div>
                  {accessLevel !== User.accessLevel.NONE && (
                    <div className="user-container">
                      <span
                        className="u-cursor-pointer u-m-x-1 u-no-ml noselect"
                        onClick={() => this.setState({ userActions: !this.state.userActions })}
                      >
                        {get(user, 'user.name', '')}
                      </span>
                      <span
                        className="u-cursor-pointer noselect"
                        onClick={() => this.setState({ userActions: !this.state.userActions })}
                      >
                        <i
                          className={`fa fa-chevron-${this.state.userActions ? 'up' : 'down'}`}
                        ></i>
                      </span>
                      <div
                        className={`user-actions-container action-container${
                          this.state.userActions ? ' open' : ''
                        }`}
                      >
                        <p className="o-logout-link u-cursor-pointer" onClick={() => this.logout()}>
                          {t('Company.Navigation.LogoutLink', '')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="e-mobilenav-links visible-xs u-m-y-1">
                {isFinland &&
                  this.renderLink('/company/targetgroups', 'Company.Pages.TargetGroups.NavHeader')}
                {isFinland &&
                  this.renderLink('/company/services', 'Company.Pages.UpdateServices.NavHeader')}
                {isFinland &&
                  this.renderLink('/company/phonebook', 'Company.Pages.PhoneBook.NavHeader')}
                {this.renderLink('/company/registers', 'Company.Pages.Landing.Header')}
                {this.renderLink('/administration', 'Company.Pages.Administration.Header')}
                {this.renderLink('/faq', 'Company.Pages.Faq.NavHeader')}
                {this.renderLink('/settings', 'Company.Pages.Settings.Header')}
              </div>
              <hr className="visible-xs" />
              <p className="visible-xs">{get(user, 'user.name', '')}</p>

              {accessLevel !== User.accessLevel.NONE && (
                <p className="visible-xs u-cursor-pointer" onClick={() => this.logout()}>
                  {t('Company.Navigation.LogoutLink', '')}
                </p>
              )}
              <hr className="visible-xs" />
              <p className="visible-xs" onClick={() => this.changeLanguage('fi')}>
                FI
              </p>
              <p className="visible-xs" onClick={() => this.changeLanguage('en')}>
                EN
              </p>
              <p className="visible-xs" onClick={() => this.changeLanguage('sv')}>
                SV
              </p>
              <p className="visible-xs" onClick={() => this.changeLanguage('de')}>
                DE
              </p>
            </div>
          </div>

          <div className="row bottom-nav hidden-xs">
            {isFinland &&
              this.renderLink('/company/targetgroups', 'Company.Pages.TargetGroups.NavHeader')}
            {isFinland &&
              this.renderLink('/company/services', 'Company.Pages.UpdateServices.NavHeader')}
            {isFinland &&
              this.renderLink('/company/phonebook', 'Company.Pages.PhoneBook.NavHeader')}
            {this.renderLink('/company/registers', 'Company.Pages.Landing.NavHeader')}
            {this.renderLink('/administration', 'Company.Pages.Administration.Header')}
            {this.renderLink('/faq', 'Company.Pages.Faq.NavHeader')}
            {this.renderLink('/administration/scythe', 'Scythe')}
            {this.renderLink('/settings', 'Company.Pages.Settings.Header')}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: state.langState.currentLanguage,
  user: state.userState.userForNavi || {},
  registers: state.registerState.registers,
  faq: state.userState.faq,
  t: phrase => get(state.langState.contentStrings, phrase, ''),
  dt: (phrase, defaultTranslation) => {
    const translation = get(state.langState.contentStrings, phrase, phrase);
    return translation === phrase ? defaultTranslation : translation;
  }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, langActions, authActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
