import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import * as authActions from '../../actions/auth-actions';
import { navigate } from '../../actions/navigate';
import Base from '../../components/Base';

export const mapStateToProps = (state, ownProps) => ({
  user: state.userState.userForNavi || {},
  isLoggedIn: authActions.isLoggedIn
});

export const mapDispatchToProps = dispatch => ({
  navigate: bindActionCreators(navigate, dispatch)
});

const BaseContainer = connect(mapStateToProps, mapDispatchToProps)(Base);

// NOTE Use of withRouter due to https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md#quick-solution
export default withRouter(BaseContainer);
